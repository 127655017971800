import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
// import { Location } from '@reach/router';
import Img from "gatsby-image";

import Layout from "../components/layout";
import Title from "../components/title";

export default function Recruit({ data }) {
  const pageData = {
    titleClass: "recruit",
    title: "採用情報",
    discription: "",
    image: 11,
  };
  const seodata = {
    title: "採用情報 ",
    seo: {
      description: "アヴィエランの採用情報",
      image: {
        url: data.datoCmsImage.topimage[11].url,
      },
    },
    slug: "recruit",
  };
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
      <div className="pankz">
        <Link to="/">トップ</Link>
        <p>採用情報</p>
      </div>
      <div className="topnews">
        <div className="topnewsimg">
          <Img fluid={data.datoCmsImage.topimage[11].news} alt="採用情報" />
          <p className="japanese">Recruit</p>
        </div>
        <div className="topnewslist recruit">
          <div className="title">
            <p>ITエンジニア・Webクリエイター</p>
            <p>IT ENGINEER / WEB CREATOR</p>
          </div>
          <div className="skill">
            <p>必要スキル・経験</p>
            <p>JavaScript,PHP,CSS,HTML,データベース</p>
            <p>正社員</p>
            <p>月給200,000円～</p>
            <p>契約社員・パート</p>
            <p>時給1,300円～</p>
          </div>

          <div className="title">
            <p>事務員</p>
            <p>CLERK</p>
          </div>
          <div className="skill">
            <p>必要スキル・経験</p>
            <p>経験不問 ※エクセル、ワード出来る方尚可</p>
            <p>正社員</p>
            <p>月給170,000円～</p>
            <p>契約社員・パート</p>
            <p>時給1,050円～</p>
          </div>

          <div className="title">
            <p>軽作業者</p>
            <p>LIGHT WORKER</p>
          </div>
          <div className="skill">
            <p>必要スキル・経験</p>
            <p>経験不問　※縫製作業の出来る方優遇</p>
            <p>正社員</p>
            <p>月給170,000円～</p>
            <p>契約社員・パート</p>
            <p>時給1,050円～</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    datoCmsImage {
      topimage {
        url
        news: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "800" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
